import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=d6d6c956"
import script from "./Button.vue?vue&type=script&lang=js"
export * from "./Button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports