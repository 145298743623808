import axios from "axios";
import Vue from 'vue';
import {getRegistrationRequestUrl, getRegistrationCancelRequestUrl} from "../../../utils/api";
import {axiosConfig} from "../../../variables";

const REGISTRATION_STATUS_SUCCESS = 'success';
const REGISTRATION_STATUS_WAITING = 'waiting';
const REGISTRATION_STATUS_FAILED = 'failed';
export default {
	//принимаем результаты отправки телефонного номера на регистрацию
	async registrationRequest({commit}, payload) {
		commit('ui/SHOW_MODAL_PRELOADER', null, { root: true });
		axios.get(getRegistrationRequestUrl(payload), axiosConfig)
			.then((response) => {
				const {status, count, message} = response.data;

				if (status === REGISTRATION_STATUS_FAILED) {
					Vue.$toast.error(message);
					commit('ui/HIDE_MODAL_PRELOADER', null, { root: true });
					commit('ui/HIDE_MODAL', null, { root: true });
					return false;
				}

				if (status === REGISTRATION_STATUS_SUCCESS) {
					commit('events/SET_IS_BOOKED', true, { root: true });
				} else {
					if (status === REGISTRATION_STATUS_WAITING) {
						commit('events/SET_IS_WAITING', true, { root: true });
					} else {
						commit('events/SET_IS_BOOKED', false, { root: true });
						commit('events/SET_BOOKING_ERROR', message, { root: true });
					}
				}
				commit('events/SET_ACTIVE_EVENT_FREE_SLOTS', count, { root: true });
				commit('calendar/SET_ACTIVE_EVENT_CHECK_FREE_SLOTS', true, { root: true });
				commit('ui/HIDE_MODAL_PRELOADER', null, { root: true });
				return true;
			}, (err) => {
				console.log(err);
				commit('ui/HIDE_MODAL_PRELOADER', null, { root: true });
				return false;
			});
	},
	async registrationCancelRequest({commit}, payload) {
		commit('ui/SHOW_MODAL_PRELOADER', null, { root: true });
		axios.get(getRegistrationCancelRequestUrl(payload), axiosConfig)
			.then((response) => {
				const {status, count, message} = response.data;
				if (status === REGISTRATION_STATUS_SUCCESS) {
					commit('events/SET_IS_REFUSED', true, { root: true });
				} else {
					commit('events/SET_BOOKING_ERROR', message, { root: true });
				}
				commit('events/SET_ACTIVE_EVENT_FREE_SLOTS', count, { root: true });
				commit('calendar/SET_ACTIVE_EVENT_CHECK_FREE_SLOTS', true, { root: true });
				commit('ui/HIDE_MODAL_PRELOADER', null, { root: true });
				return true;
			}, (err) => {
				console.log(err);
				commit('ui/HIDE_MODAL_PRELOADER', null, { root: true });
				return false;
			});
	},
};
